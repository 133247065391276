/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { Wrapper } from "./styled";
import { USER_PROFILE } from "store/Login/initialState";
import { Item } from "./styled";
import { get, omitBy, isNull } from "lodash";

const Content = () => {
  const metadata = useSelector(selectPaperworkMetadata);
  const user = get(metadata, "user", {});
  const nonNullUsers = omitBy(user, isNull);
  const cuit = get(nonNullUsers, "cuit", "-");
  const name = get(nonNullUsers, "name", "-");
  const email = get(nonNullUsers, "email", "-");
  const profileId = get(nonNullUsers, "profileId", "0");
  const profile = get(USER_PROFILE, `${profileId}`, "-");
  console.log(nonNullUsers);
  return (
    <Wrapper>
      <Item children={`Nombre y apellido: ${name}`} />
      <Item children={`CUIT DNI: ${cuit}`} />
      <Item children={`Profesión: ${profile}`} />
      <Item children={`Email: ${email}`} />
    </Wrapper>
  );
};

export default Content;
